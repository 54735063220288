@use "../global" as g;

.commonplanSec {
  margin-bottom: 30px;

  @include g.mq-down(lg) {
    margin-bottom: 30px;
  }

  .inner {
    width: calc(100% - 60px);
    max-width: 1144px;
    margin: 0 auto;

    .midashi {
      width: 463px;
      margin: -15px auto 80px;

      @include g.mq-down(lg) {
        width: 100%;
        margin: 0px auto 20px;
        position: relative;
        top: g.spvw(-10);
      }
    }

    .slides {
      margin-bottom: 60px;

      @include g.mq-down(lg) {
        margin-left: -30px;
        width: calc(100% + 60px);
      }

      .slide {
        margin: 0 14px;

        @include g.mq-down(lg) {
          margin: 0;
        }

        a {
          display: block;
          width: 258px;

          @include g.mq-down(lg) {
            width: calc(100% - 22px);
            margin: 0 auto;
          }

          figure {
            margin-bottom: 10px;
          }

          .category {
            margin-bottom: 10px;

            span {
              display: inline-block;
              font-size: 1.2rem;
              margin: 0 3px 3px 0;
              color: #fff;
              background: g.$thin-brown;
              padding: 2px 7px;
            }
          }

          .title {
            color: g.$brown;
            font-size: 1.4rem;
            margin-bottom: 10px;
          }

          .price {
            background: g.$bg-grey;
            padding: 3px 10px;
            color: g.$brown;
            font-size: 1.8rem;

            .unit {
              font-size: 1.3rem;
              color: g.$text-color;
            }
          }
        }
      }

      .slick-prev {
        left: -45px;

        @include g.mq-down(xxxl) {
          left: -30px;
        }

        @include g.mq-down(lg) {
          left: 20px;
        }
      }

      .slick-next {
        right: -45px;

        @include g.mq-down(xxxl) {
          right: -30px;
        }

        @include g.mq-down(lg) {
          right: 20px;
        }
      }

      .slick-prev::before,
      .slick-next::before {
        content: "" !important;
        background-image: url(../images/common/btn_slidearrow2.svg);
      }


    }

    .btnwrap {
      text-align: center;
    }
  }
}
