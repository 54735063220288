@use "../global" as g;

footer {

  .sitemapSec {
    //background: g.$footer-grey;
    background-image: url(../images/common/img_sitemap-01_pc.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 70px 0 30px;
    color: #fff;

    @include g.mq-down(lg) {
      background-image: url(../images/common/img_sitemap-01_sp.jpg);
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1210px;
      margin: 0 auto;

      .content-box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: stretch;

        @include g.mq-down(lg) {
          display: block;
        }

        .inquiries {
          width: 46%;
          margin-left: 8%;

          @include g.mq-down(lg) {
            width: 100%;
            margin-left: 0;
          }

          .midashi {
            font-size: 2rem;
            margin-bottom: 8px;
            letter-spacing: 3px;

            @include g.mq-down(lg) {
              text-align: center;
              margin-bottom: 14px;
            }
          }

          .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include g.mq-down(lg) {
              display: block;
            }

            li {
              width: calc(50% - 6px);
              margin-top: 12px;

              @include g.mq-down(lg) {
                width: 100%;
                margin-top: 6px;
              }
            }

            &.webform {
              margin-bottom: 40px;

              a {
                .icon {
                  @include g.mq-up(lg) {
                    display: none;
                  }
                }

                &.el_btn_gl {
                  border-color: g.$gold;
                }
              }
            }


            &.telephone {
              color: #fff;

              li {
                @include g.mq-down(lg) {
                  text-align: center;
                  border-bottom: 1px solid g.$grey;
                  padding-bottom: 20px;
                  margin-bottom: 20px;

                  &:nth-last-of-type(1) {
                    border-bottom: none;
                  }

                  .komidashi {
                    margin-bottom: 10px;
                  }
                }
              }

              li:first-child {
                border-right: 1px solid g.$grey;

                @include g.mq-down(lg) {
                  border-right: none;

                }
              }

              li:last-child {
                padding-left: 20px;

                @include g.mq-down(lg) {
                  padding-left: 0;
                }
              }

              .number {
                display: block;
                font-size: 1.6rem;
                letter-spacing: 1px;
                margin-bottom: 5px;

                @include g.mq-down(lg) {
                  border: 1px solid #fff;
                  text-align: center;
                  padding: 10px 0;
                  margin-bottom: 10px;
                }

                span {
                  display: inline-block;
                  font-size: 2.1rem;
                }
              }
            }
          }
        }

        .sitemap {
          width: 54%;
          padding-right: 2%;
          border-right: 1px solid g.$grey;
          color: #fff;

          @include g.mq-down(lg) {
            display: none;
          }

          .logo {
            width: 234px;
            height: 40px;
            margin-bottom: 40px;
            position: relative;
            top: -10px;
          }

          .index {
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;

            &--sp {
              display: none;
            }

            @include g.mq-down(lg) {
              &--pc {
                display: none;
              }

              &--sp {
                display: block;
                font-size: 1.3rem;
              }
            }

            .list {
              li {
                margin-bottom: 15px;

                @include g.mq-down(lg) {
                  text-align: center;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                &.insta {
                  margin-top: 25px;

                  svg {
                    width: 20px;
                    height: 20px;
                    fill: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .corp-nav {
        margin-top: 30px;

        .index {
          &--sp {
            display: none;
          }

          @include g.mq-down(lg) {
            &--pc {
              display: none;
            }

            &--sp {
              display: block;
            }
          }


        }

        .list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          li {
            padding: 0 10px;
            line-height: 1;
            letter-spacing: 1px;
            font-size: 1.2rem;

            @include g.mq-down(lg) {
              width: auto;
            }

            &::after {
              content: "";
              width: 1px;
              height: 100%;
              background: g.$text-color;
              display: inline-block;
              margin-left: 20px;

              @include g.mq-down(lg) {
                content: none;
              }
            }

            &:last-child {
              &::after {
                content: none;
              }

              @include g.mq-down(lg) {
                width: 100%;
                text-align: center;
                margin-top: 20px;

                .icon {
                  width: 18px;
                  height: 18px;
                  display: inline-block;
                  vertical-align: middle;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

    }
  }

  .copyrightSec {
    background: g.$brown;
    text-align: center;
    padding: 25px 0;
    color: #fff;
    line-height: 1;
    letter-spacing: 1px;

    @include g.mq-down(lg) {
      padding: 25px 0 40px;
      text-align: left;
      background: #837572;
      ;
      color: #fff;

      div {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        p {
          font-size: 1rem;

          span {
            display: inline;
          }
        }

        .corp-logo {
          width: 65px;
          height: 30px;
        }

      }

    }
  }

  .pcGroupSec {
    background: g.$brown;
    color: #fff;
    padding: 70px 0 80px;
  }

  .pcGroupSec .inner {
    width: calc(100% - 40px);
    max-width: 1100px;
    margin: 0 auto;
  }

  .pcGroupSec .inner .midashi {
    margin-bottom: 40px;
  }

  .pcGroupSec .inner .midashi span.en {
    margin-right: 20px;
    font-size: 1.8rem;
    letter-spacing: 1px;
  }

  .pcGroupSec .inner .contentBox {
    display: flex;
    align-items: stretch;
  }

  .pcGroupSec .inner .contentBox .col {
    width: 380px;
  }

  .pcGroupSec .inner .contentBox .col .block {
    margin-bottom: 3rem;
    font-size: 1.2rem;
    line-height: 2.8;
  }

  .pcGroupSec .inner .contentBox .col .block .komidashi {
    font-weight: 700;
  }

  .pcGroupSec .inner .contentBox .col .block:last-child {
    margin-bottom: 0;
  }

  .pcGroupSec .inner .midashi {
    margin-bottom: 40px;
  }

  .pcGroupSec .inner .midashi span {
    display: inline-block;
    vertical-align: middle;
  }

  .spGroupSec {
    background: g.$brown;
    padding: 60px 0 30px;
    color: #fff;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 40px);
      height: 1px;
      background: #fff;
      margin-left: 40px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1100px;
      margin: 0 auto;

      .logo {
        width: 175px;
        margin: 0 auto 50px;
      }

      .companyinfo1 {
        margin-bottom: 20px;
        line-height: 2;

        li {
          text-align: center;
          margin-bottom: 10px;
          letter-spacing: 0.5px;
          font-size: 1.3rem;
        }

        ;
      }

      .companyinfo2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        line-height: 2;

        li {
          margin: 0 8px;
          text-align: center;
          letter-spacing: 0.5px;
          font-size: 1.3rem;

          &:nth-last-of-type(1) {
            width: 100%;

            span {
              display: inline-block;
              vertical-align: text-bottom;
              width: 15px;
              height: 13px;

              margin-left: 10px;

              svg {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}
