@use "../global" as g;

.p-top {

  .opening {
    background: g.$brown;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    .movie {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .movie__video {
        object-fit: cover;
        height: calc(100% + 1px);
        width: 100%;
      }
    }
  }

  header {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .mvSec {
    width: 100%;
    height: 100vh;
    min-height: 750px;
    position: relative;
    z-index: 2;
    background: g.$brown;

    @include g.mq-down(lg) {
      height: g.spvw(355);
      min-height: auto;
      //margin-top: 75px;
    }

    .movie {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: calc(100% - 44px);
      height: calc(100% - 44px);
      overflow: hidden;

      @include g.mq-down(lg) {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
      }

      .movie__video {
        object-fit: cover;
        height: calc(100% + 1px);
        width: 100%;
      }
    }

    .clip {
      width: 100%;
      height: calc(100vh + 987px);
      background: #897976;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      z-index: 0;

      @include g.mq-down(lg) {
        height: calc(g.spvw(335) + g.spvw(260));
      }
    }

  }

  .importantTopicsSec {
    padding: 10px 0 120px;
    position: relative;

    background: g.$brown;

    @include g.mq-down(lg) {
      padding: 10px 0 70px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1207px;
      margin: 0 auto;
      text-align: center;
      position: relative;
      z-index: 2;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .conceptSec {
    background: g.$brown;
    padding: 0 0 80px;
    color: #fff;

    @include g.mq-down(lg) {
      padding-bottom: 70px;
    }

    .inner {
      position: relative;
      z-index: 2;
      padding-left: calc(50vw - 626px / 2);
      padding-top: 50px;

      @include g.mq-down(lg) {
        padding: 0 0 0 20px;
      }

      .midashi {
        position: absolute;
        top: 0;
        left: 0;
        width: 662px;
        padding-left: calc(50vw - 1206px / 2);
        box-sizing: content-box;
        z-index: 2;

        @include g.mq-down(lg) {
          position: relative;
          //width: calc(100% - 20px);
          width: g.spvw(335);

        }
      }

      .slidewrap {
        display: flex;
        width: 100%;
        overflow: hidden;
        margin-bottom: 50px;

        @include g.mq-down(lg) {
          margin-top: -8px;
          margin-bottom: 40px;
        }

        .slider {
          //display: flex;
          //animation: 80s linear infinite both sliderAnimation_horizontal;
          width: min-content;

          .slide {
            width: 874px;
            height: 534px;

            @include g.mq-down(lg) {
              width: g.spvw(344);
              height: g.spvw(210);
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit();
            }
          }
        }
      }

      .catch {
        font-size: 2.4rem;
        letter-spacing: 2px;
        line-height: 1.8;
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          padding-left: 10px;
        }
      }

      .read {
        line-height: 2;

        @include g.mq-down(lg) {
          padding-left: 10px;
          padding-right: 20px;
        }
      }
    }

    @keyframes sliderAnimation_horizontal {
      100% {
        transform: translateX(-50%);
      }
    }
  }

  .weddingstyleSec {
    background: g.$brown;
    padding-bottom: 240px;
    color: #fff;
    position: relative;
    z-index: 2;

    @include g.mq-down(lg) {
      padding-bottom: g.spvw(140);
    }

    .midashi {
      width: 457px;
      position: absolute;
      top: 269px;
      left: calc(50% + 220px);
      z-index: 2;

      @include g.mq-down(lg) {
        position: relative;
        top: 0;
        left: 0;
        width: g.spvw(248);
        height: g.spvw(73);
        margin-left: calc(100% - g.spvw(248));
      }
    }

    .slidewrap {
      display: flex;
      width: calc(50% + 365px);
      overflow: hidden;
      margin-bottom: 50px;

      @include g.mq-down(lg) {
        margin-top: -16px;
        width: calc(100% - 20px);
        margin-bottom: 40px;
      }

      .slider {
        display: flex;
        animation: 60s linear infinite both sliderAnimation_horizontal;
        width: min-content;

        .slide {
          width: 618px;
          height: 403px;

          @include g.mq-down(lg) {
            width: g.spvw(335);
            height: g.spvw(210);
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }
      }
    }

    .container {
      padding-left: calc(50vw - 626px / 2);

      @include g.mq-down(lg) {
        padding: 0 30px;
      }

      .catch {
        font-size: 2.2rem;
        letter-spacing: 2px;
        line-height: 1.8;
        margin-bottom: 30px;
      }

      .read {
        line-height: 2;
        margin-bottom: 30px;
      }

    }

  }

  .facilitySec {
    background: #EFEDED;
    padding-bottom: 117px;

    @include g.mq-down(lg) {
      padding-bottom: 80px;
    }

    .midashi {
      width: 481px;
      margin: -100px auto 0;
      position: relative;
      z-index: 2;

      @include g.mq-down(lg) {
        width: g.spvw(304);
        height: g.spvw(128.19);
        margin-top: g.spvw(-128.19) / 2;
        margin-left: calc(100% - g.spvw(304) - 20px);
      }
    }

    .main-catch {
      font-size: 2.2rem;
      letter-spacing: 2px;
      position: relative;
      top: -20px;
      padding-left: calc(50% - 240px);
      margin-bottom: 80px;
      z-index: 3;

      @include g.mq-down(lg) {
        top: -40px;
        padding-left: 10px;
        margin-bottom: 50px;
      }
    }

    .kv {
      width: 100%;
      height: 669px;
      margin-bottom: 115px;

      @include g.mq-down(lg) {
        width: g.spvw(324);
        height: g.spvw(421);
        margin-left: calc(100% - g.spvw(324));
      }

      figure {
        width: 100%;
        height: 100%;
        @include g.imgFit();
      }
    }

    .box {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      @include g.mq-down(lg) {
        display: block;
      }

      .slidewrap {
        width: calc(50vw + 50px);
        overflow: hidden;

        @include g.mq-down(lg) {
          width: calc(100% - 20px);
          margin-bottom: 40px;
        }

        .slider {
          display: flex;
          animation: 60s linear infinite both sliderAnimation_horizontal;
          width: min-content;

          .slide {
            width: 693px;
            height: 468px;

            @include g.mq-down(lg) {
              width: g.spvw(323);
              height: g.spvw(218);
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit();
            }
          }
        }
      }

      .nakamidashi {
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          padding-left: 20px;
          margin-bottom: 10px;
        }

        .en {
          font-size: 3rem;
          color: #C3BCBC;
          letter-spacing: 2px;
        }

        .ja {
          color: g.$brown;

        }
      }

      .catch {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          padding-left: 50px;
        }
      }

      .text {
        width: 501px;
        padding-left: g.pcminvw(130);

        @include g.mq-down(lg) {
          width: 100%;
        }


        .read {
          line-height: 1.8;
          margin-bottom: 20px;

          @include g.mq-down(md) {
            margin-bottom: 30px;
            padding-right: 20px;
          }
        }

        .more {
          width: calc(100% - 20px);

        }
      }
    }
  }

  .weddingcontentsSec {
    background: #fff;

    .midashi {
      width: calc(100% - 60px);
      max-width: 1206px;
      margin: 0 auto;
      position: relative;
      top: -20px;

      @include g.mq-down(lg) {
        top: -13px;
        margin-bottom: 37px;
      }

      .en {
        width: 396px;
        margin-bottom: 60px;

        @include g.mq-down(lg) {
          width: g.spvw(270);
          margin-bottom: 30px;
        }
      }

      .ja {
        font-size: 2.2rem;
        line-height: 1.8;
        padding-left: 100px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
          padding-left: 0;
        }
      }
    }

    .list {
      display: flex;

      @include g.mq-down(lg) {
        display: block;
      }

      .card {
        width: 33.3333%;
        height: 619px;

        @include g.mq-down(lg) {
          width: 100%;
          height: g.spvw(375);
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: relative;
          color: #fff;

          @include g.mq-up(lg) {
            @include g.image-link_zoom();
          }

          figure {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            @include g.imgFit(50%, 10%);
          }

          .text {
            position: relative;
            z-index: 1;

            .komidashi {
              margin-bottom: 20px;

              @include g.mq-down(lg) {
                margin-bottom: 15px;
              }

              span {
                display: block;

                &.en {
                  text-align: center;
                  height: 24px;
                  margin-bottom: 15px;

                  @include g.mq-down(lg) {
                    margin-bottom: 10px;
                  }

                  img {
                    width: auto;
                    height: 100%;
                  }
                }

                &.ja {
                  text-align: center;
                }
              }
            }

            .catch {

              text-align: center;
              font-size: 1.6rem;
              line-height: 2;
              letter-spacing: 1px;
            }
          }

          .more {
            width: 60%;
            max-width: 192px;
            pointer-events: none;
            position: absolute;
            bottom: 65px;
            left: 50%;
            z-index: 1;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-between;

            @include g.mq-down(lg) {
              bottom: 25px;
            }
          }
        }
      }
    }
  }

  .topicsSec {
    padding: 85px 0 107px;
    background: #EFEDED;

    .inner {
      padding-left: calc(50vw - 806px / 2);
      position: relative;

      &::before {
        display: block;
        width: 468px;
        height: 468px;
        border: 60px solid #F4F1ED;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        top: 150px;
        left: calc(50vw - 289px - 280px);
        z-index: -1;
        opacity: .6;

        @include g.mq-down(lg) {
          top: 200px;
          left: 50%;
          transform: translateX(-50%);
          width: 279px;
          height: 279px;
          border: 50px solid #F4F1ED;
        }
      }

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding-left: 0;
      }

      .midashi {
        font-size: 4rem;
        letter-spacing: 4px;
        font-weight: 700;
        color: g.$brown;

        @include g.mq-down(lg) {
          font-size: 2rem;
        }
      }

      .list {
        dl {
          display: flex;
          border-bottom: 1px solid g.$grey;
          padding-bottom: 15px;
          margin-top: 30px;

          @include g.mq-down(lg) {
            display: block;
            padding-bottom: 10px;
            margin-top: 15px;
          }

          dt {
            width: 80px;
            margin-right: 20px;

            @include g.mq-down(lg) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 10px;
            }
          }

          dd {
            width: calc(100% - 100px);
            padding-right: calc(50vw - 551.5px);

            @include g.mq-down(lg) {
              width: 100%;
              padding-right: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

}
