@use "../global" as g;

/* CSS Document */
/* ----------------------------------------------------------------------------------------------------
  settings
* --------------------------------------------------------------------------------------------------*/
html {
  font-size: 62.5%;
}

body {
  //overflow-x: hidden;
  //overflow-y: scroll;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  color: g.$text-color;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "Noto Sans JP", Helvetica, Arial,
    "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka,
    "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6;

  @include g.mq-down(lg) {
    font-size: 1.2rem;
  }

  -webkit-overflow-scrolling: touch;
  -webkit-print-color-adjust: exact;
  text-justify: inter-ideograph;
  -webkit-text-size-adjust: 100%;
}

//body.win {
//  font-family: Arial, 'Open Sans', 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro',
//    'Noto Sans JP', 游ゴシック体, 'Yu Gothic', YuGothic, メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック',
//    'MS PGothic', sans-serif;
//}
.stopscroll {
  overflow: hidden;
}

.lato {
  font-family: 'Lato', sans-serif;
  transform: scaleY(0.95);
}

.reemkufi {
  font-family: 'Reem Kufi', sans-serif;
}

.cormorant {
  font-family: 'Cormorant Garamond', serif;
}

.mincho {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
}

.icon svg {
  width: 100%;
  height: 100%;
  display: block;
}

.italic {
  font-style: italic;
}

main {
  display: block;
}

.bold {
  font-weight: 700;
}

.ie .slick-dots li button:before {
  font-size: 20px;
}

*:focus {
  outline: none;
}

@include g.mq-up(lg) {
  body {
    //min-width: 1180px;
  }

  .sp_view {
    display: none;
  }

  .pc_view {
    display: block;
  }
}

@include g.mq-down(lg) {
  html {
    overflow-x: hidden;
  }

  .sp_view {
    display: block;
  }

  .pc_view {
    display: none;
  }

  input[type="text"],
  input[type="search"],
  input[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
  }
}
