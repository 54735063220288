@use "../global" as g;

.commonfairSec {
  padding: 102px 0 156px;
  background: #EFEDED;



  .inner {
    .midashi {
      width: 367px;
      margin: 0 auto -24px;


      @include g.mq-down(lg) {
        width: g.spmaxvw(260);
        margin: 0px auto 0px;
        top: -2.333vw;
      }
    }
  }
}
