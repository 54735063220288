@use "../global" as g;

.p-small-wedding {

  .outlineSec {
    margin-bottom: 80px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;

      .midashi {
        margin-bottom: 30px;
        font-size: 2.4rem;
        line-height: 1.8;
        letter-spacing: 2px;
        text-align: center;

        @include g.mq-down(lg) {
          margin-bottom: 20px;
          font-size: 1.8rem;
        }
      }

      .read {
        font-size: 13px;
        line-height: 1.8;
        text-align: center;

        @include g.mq-down(lg) {
          text-align: left;
        }
      }
    }
  }

  .recommendSec {
    padding: 0 0 120px;

    @include g.mq-down(lg) {
      padding: 0 0 60px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 35px;
        color: g.$brown;

        .en {
          position: relative;
          margin-bottom: 5px;
          font-size: 2.8rem;
          letter-spacing: 4px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
          }

          span {
            background: #fff;
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-right: 40px;

            @include g.mq-down(lg) {
              padding-right: 20px;
            }
          }

          &::after {
            content: "";
            background: #d0cec6;
            width: calc(100% + calc(50vw - 550px));
            height: 1px;
            display: block;
            position: absolute;
            bottom: 50%;
            left: 0;
            z-index: 0;

            @include g.mq-down(lg) {
              width: calc(100% + 30px);
            }
          }
        }

        .ja {
          letter-spacing: 1px;
        }
      }

      .list {
        li {
          @include g.mq-down(lg) {
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          figure {
            margin-bottom: 18px;
          }

          .caption {
            p {
              font-size: 1.8rem;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
  }

  .reasonSec {
    background: g.$bg-grey;
    padding: 120px 0 10px;

    @include g.mq-down(lg) {
      padding: 70px 0 10px;
    }

    .inner {
      padding-left: calc(50vw - 550px);

      @include g.mq-down(lg) {
        padding: 0 0 0 30px;
      }

      .midashi {
        margin-bottom: 50px;
        color: g.$brown;

        .en {
          margin-bottom: 5px;
          font-size: 2.8rem;
          letter-spacing: 4px;
          position: relative;

          @include g.mq-down(lg) {
            font-size: 2.4rem;
          }

          span {
            background: g.$bg-grey;
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-right: 40px;

            @include g.mq-down(lg) {
              padding-right: 20px;
            }
          }

          &::after {
            content: "";
            background: #d0cec6;
            width: 100%;
            height: 1px;
            display: block;
            position: absolute;
            bottom: 50%;
            left: 0;
            z-index: 0;

            @include g.mq-down(lg) {
              width: calc(100% + 30px);
            }
          }
        }

        .ja {
          letter-spacing: 1px;
        }
      }

      .slides {
        position: relative;
        margin-bottom: 100px;
      }
    }
  }

  .ceremonyStyleSec {
    background: g.$bg-grey;
    padding: 90px 0;

    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 35px;
        color: g.$brown;

        .en {
          margin-bottom: 5px;
          font-size: 2.8rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
          }
        }

        .ja {
          letter-spacing: 1px;
        }
      }

      .list {
        li {
          @include g.mq-down(lg) {
            margin-bottom: 40px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        figure {
          margin-bottom: 18px;

          @include g.mq-down(lg) {
            margin-bottom: 10px;
          }
        }

        img {
          @include g.mq-down(lg) {
            height: 60vw;
            object-fit: cover;
          }
        }

        .caption {
          .komidashi {
            margin-bottom: 13px;
            color: g.$brown;
            font-size: 1.6rem;
            letter-spacing: 1px;

            @include g.mq-down(lg) {
              margin-bottom: 8px;
            }
          }

          .read {
            margin-bottom: 30px;
            font-size: 13px;
            line-height: 1.8;
          }
        }
      }
    }
  }

  .planSec {
    padding: 100px 0;

    @include g.mq-down(lg) {
      padding: 70px 0;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        color: g.$brown;
        text-align: center;
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          margin-bottom: 30px;
        }

        .en {
          font-size: 2.8rem;
          letter-spacing: 4px;
          margin-bottom: 5px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
          }
        }
      }

      .plan {

        &__list {

          @include g.mq-down(lg) {
            margin-top: 50px;
          }

          &__item {

            padding: 35px;
            border: 1px solid g.$grey;

            @include g.mq-down(lg) {
              padding: 20px;
            }

            &:nth-child(n+2) {
              margin-top: 30px;

              @include g.mq-down(lg) {
                margin-top: 30px;
              }
            }

            &>a {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }

            &__thumbnail {
              width: 30.5%;
              height: 314px;

              @include g.mq-down(lg) {
                width: 100%;
                height: 78.667vw;
              }

              figure {
                width: 100%;
                height: 100%;
                @include g.imgFit();
              }
            }

            &__detail {
              width: 61%;
              padding-top: 25px;

              @include g.mq-down(lg) {
                width: 100%;
                padding-top: 15px;
              }

            }

            &__name {
              margin-bottom: 15px;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 0.05em;
              line-height: 1.44;
              color: g.$brown;

              @include g.mq-down(lg) {
                font-size: 16px;
              }
            }

            &__tag {
              display: inline-block;
              margin-right: 1px;
              padding: 3px 9px;
              font-size: 11px;
              font-weight: 500;
              color: #fff;
              letter-spacing: 0.05em;
              text-align: center;
              background: g.$brown;
            }

            &__tagBox {
              margin-bottom: 20px;
            }

            &__label {
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              width: 85px;
              font-size: 13px;
              font-weight: bold;
              color: g.$text-color;
              letter-spacing: 0.05em;
            }

            &__price,
            &__period,
            &__number {
              position: relative;
              padding-left: 80px;
              letter-spacing: 0.05em;

              @include g.mq-down(lg) {
                padding-left: 0;
              }
            }

            &__price {
              margin-bottom: 13px;
              font-size: 25px;
              font-weight: bold;
              color: g.$brown;
              letter-spacing: 0.05em;
              line-height: 1;
              display: flex;
              align-items: flex-end;

              @include g.mq-down(lg) {
                margin-bottom: 20px;
                font-size: 22px;
              }

              &__label {
                top: auto;
                bottom: 4px;

                @include g.mq-down(lg) {
                  font-size: 1.3rem;
                  color: #000;
                  margin-right: 20px;
                }
              }
            }

            &__period {
              margin-bottom: 10px;
            }

            &__number {
              margin-bottom: 23px;
            }

            &__link {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .recommendfairSec {
    background-image: url(../images/small-wedding/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/small-wedding/img_recommendfair-01_sp.jpg);
    }
  }

  .reportSec {
    background: g.$bg-grey;
    padding: 60px 0 80px;

    .inner {
      width: 100%;
      margin: 0 auto;

      .midashi {
        color: g.$brown;
        text-align: center;
        margin-bottom: 40px;

        .en {
          font-size: 3rem;
          letter-spacing: 2px;
          margin-bottom: 5px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
          }

        }
      }

      .reportSlides {
        width: 100%;
        max-width: 1120px;
        margin: 0 auto 60px;

        .slide {
          margin: 0 10px;

          @include g.mq-down(lg) {
            margin: 0 10px;
            width: calc(100vw - 40px);
          }

          figure {
            width: 100%;
            height: 246px;

            @include g.mq-down(lg) {
              height: 56vw;
            }

            @include g.imgFit();
          }

          .caption {
            background: g.$bg-grey;
            text-align: center;
            color: g.$brown;
            font-size: 1.6rem;
            letter-spacing: 2px;
            padding: 20px 20px;
          }
        }

        .slick-prev {
          left: -25px;

          @include g.mq-down(lg) {
            left: 20px;
          }

        }

        .slick-next {
          right: -25px !important;

          @include g.mq-down(lg) {
            right: 20px !important;
          }
        }
      }

      .more {
        text-align: center;
        padding: 0 30px;
      }
    }
  }

  .faqSec {

    &__head {
      padding-bottom: 55px;
      color: g.$brown;
      text-align: center;

      @include g.mq-down(lg) {
        padding-bottom: 30px;
      }

      &__ja,
      &__en {
        display: block;
      }

      &__en {
        margin-bottom: 10px;
        font-size: 30px;
        letter-spacing: .1em;

        @include g.mq-down(lg) {
          font-size: 20px;
        }
      }

      &__ja {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .1em;
      }
    }

    &__faq {
      padding: 80px 0 100px;

      @include g.mq-down(lg) {
        padding: 80px 0;
      }

      &__inner {
        @include g.mq-down(lg) {
          width: 100%;
        }
      }

      &__list {
        margin-bottom: 60px;

        dt {
          background: g.$bg-grey;
          margin-bottom: 24px;
          padding: 30px 0;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: .05em;

          @include g.mq-down(lg) {
            margin-bottom: 20px;
            padding: 20px 5%;
            font-size: 13px;
            line-height: 1.5;
          }

          &::before {
            content: "Q";
            top: 25px;

            @include g.mq-down(lg) {
              top: 15px;
            }
          }
        }

        dd {
          font-size: 13px;
          font-weight: 500;
          line-height: 2.07;
          letter-spacing: .05em;

          @include g.mq-down(lg) {
            padding-right: 5%;
          }

          &::before {
            content: "A";
            top: 0;

            @include g.mq-down(lg) {
              top: -5px;
            }
          }

          +dt {
            margin-top: 55px;

            @include g.mq-down(lg) {
              margin-top: 40px;
            }
          }
        }

        dt,
        dd {
          position: relative;
          padding-left: 64px;

          @include g.mq-down(lg) {
            padding-left: 50px;
          }

          &::before {
            position: absolute;
            left: 30px;
            color: g.$brown;
            font-family: "Libre Baskerville", serif;
            font-size: 18px;
            letter-spacing: .1em;

            @include g.mq-down(lg) {
              left: 20px;
            }
          }

          .el_btnText_or {
            display: inline;
            padding-bottom: 10px;
            border-bottom: 1px solid g.$gold;
            color: g.$gold;
            transition: opacity .2s;
            font-weight: bold;
          }
        }
      }

      .more {
        text-align: center;
        padding: 5%;
        font-weight: bold;

        @include g.mq-down(lg) {
          padding: 0 30px;
        }
      }
    }
  }
}
