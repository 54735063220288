@use "../global" as g;

.othercontentsSec {
  padding: 100px 0;

  @include g.mq-down(lg) {
    padding: 60px 0;
  }

  .inner {
    width: calc(100% - 60px);
    max-width: 1100px;
    margin: 0 auto;

    .midashi {
      color: g.$brown;
      margin-bottom: 40px;

      .en {
        font-size: 2.8rem;
        letter-spacing: 4px;
        position: relative;

        @include g.mq-down(lg) {
          font-size: 2.4rem;
        }

        &::after {
          content: "";
          display: block;
          width: calc(100% + 50vw - 550px);
          height: 1px;
          background: g.$grey;
          position: absolute;
          top: calc(50% - 0.5px);
          left: 0;
          z-index: -1;

          @include g.mq-down(lg) {
            width: calc(100% + 30px);
          }
        }

        span {
          display: inline-block;
          background: #F8F9F5;
          position: relative;
          padding-right: 40px;
          z-index: 1;

          @include g.mq-down(lg) {
            padding-right: 20px;
          }
        }
      }
    }

    .list {

      .card {
        a {
          display: block;
        }

        figure {
          width: 100%;
          height: 22vw;
          max-height: 246px;
          @include g.imgFit();

          @include g.mq-down(lg) {
            height: 64vw;
            max-height: none;
          }
        }

        .caption {
          color: g.$brown;
          text-align: center;

          .en {
            font-size: 1.8rem;
            letter-spacing: 4px;
          }
        }
      }
    }
  }
}
