@use "../global" as g;

.p-concept {
  main {
    margin-top: 0;

    @include g.mq-down(lg) {
      margin-top: 75px;
    }
  }

  .concept-sec {
    background: g.$brown;
    color: #fff;
    padding-bottom: 140px;
    position: relative;

    @include g.mq-down(lg) {
      padding-bottom: 60px;
    }

    .kv {
      padding-bottom: 100px;
      position: relative;

      figure {
        width: 100%;
      }

      .text {
        padding-left: calc(50vw - 1103px / 2);
        margin: -170px auto 0;

        @include g.mq-down(lg) {
          width: calc(100% - 40px);
          margin: -35px auto 0;
        }

        .midashi {
          width: 662px;
          margin-bottom: 100px;

          @include g.mq-down(lg) {
            width: 320px;
            margin-bottom: 30px;
          }
        }

        .catch {
          font-size: 3rem;
          letter-spacing: 2px;
          line-height: 2;
          padding-left: g.pcminvw(180);
          margin-bottom: 30px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
            padding-left: 0;
            margin-bottom: 20px;
          }
        }

        .read {
          padding-left: g.pcminvw(180);
          line-height: 2.2;

          @include g.mq-down(lg) {
            padding-left: 0;
          }
        }
      }
    }

    .location {
      padding-left: calc(50vw - 1103px / 2 + g.pcminvw(180));
      position: relative;

      @include g.mq-down(lg) {
        padding-left: 20px;
      }

      .midashi {
        font-size: 4rem;
        letter-spacing: 4px;
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          font-size: 3rem;
        }
      }

      .list {
        display: flex;

        @include g.mq-down(lg) {
          display: block
        }

        .card {


          @include g.mq-down(lg) {
            padding-left: 55px;
            margin-bottom: 40px;
          }

          figure {
            @include g.imgFit();
            margin-bottom: 25px;
          }

          .catch {
            font-size: 1.8rem;
            letter-spacing: 1px;
            margin-bottom: 15px;

            @include g.mq-down(lg) {
              padding-right: 20px;
            }
          }

          .read {
            line-height: 2;

            @include g.mq-down(lg) {
              padding-right: 20px;
            }
          }

          &:nth-child(1) {
            width: g.pcminvw(358);
            margin-right: g.pcminvw(46);

            @include g.mq-down(lg) {
              width: 100%;

            }

            figure {
              width: 100%;
              height: 477px;

              @include g.mq-down(lg) {
                height: g.spvw(320);
              }
            }
          }

          &:nth-child(2) {
            width: calc(100% - g.pcminvw(404));

            @include g.mq-down(lg) {
              width: 100%;

            }

            figure {
              width: 100%;
              height: 477px;

              @include g.mq-down(lg) {
                height: g.spvw(320);
              }
            }
          }
        }
      }
    }

    .clip {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 370px);
      background: #897976;
      clip-path: polygon(100% 0, 0 0, 100% 100%);
      z-index: 0;

      @include g.mq-down(lg) {
        height: calc(g.spvw(335) + g.spvw(260));
      }
    }
  }

  .guesthouse-sec {
    background: #EFEDED;
    padding-bottom: 270px;
    position: relative;

    @include g.mq-down(lg) {
      padding-bottom: 90px;
    }

    .clip {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 152px);
      background: #E6E2E2;
      clip-path: polygon(100% 0, 0 0, 0 100%);
      z-index: 0;

      @include g.mq-down(lg) {
        height: calc(g.spvw(335) + g.spvw(260));
      }
    }

    .kv {
      width: calc(100% - 64px);
      height: 660px;
      position: relative;
      color: #fff;
      display: flex;
      align-items: center;
      margin-bottom: 110px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(375);
        margin-bottom: 30px;
      }

      figure {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        @include g.imgFit(50%, 100%);
      }

      .copy {
        position: relative;
        z-index: 1;
        padding-left: calc(50vw - 1103px / 2 + g.pcminvw(180));

        @include g.mq-down(lg) {
          padding-left: 30px;
        }

        .midashi {
          font-size: 3.8rem;
          letter-spacing: 5px;
          line-height: 1.4;
          margin-bottom: 15px;

          @include g.mq-down(lg) {
            font-size: 3rem;
            line-height: 1.3;
            margin-bottom: 10px;
          }
        }

        .catch {
          font-size: 1.8rem;
          line-height: 1.8;
          margin-bottom: 10px;

          @include g.mq-down(lg) {
            line-height: 1.8;
            margin-bottom: 10px;
          }
        }

        .read {
          line-height: 2.2;

        }

        ;
      }

      @include g.mq-down(lg) {
        &+.read {
          position: relative;
          padding-left: 30px;
          padding-right: 20px;
          line-height: 2;
          margin-bottom: 60px;
        }
      }
    }

    .text {
      position: relative;
      padding-left: calc(50vw - 1103px / 2 + g.pcminvw(180));

      @include g.mq-down(lg) {
        padding-left: 30px;
      }

      .slidewrap {
        display: flex;
        width: 100%;
        overflow: hidden;
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          margin-bottom: 30px;
        }

        .slider {
          display: flex;
          animation: 80s linear infinite both sliderAnimation_horizontal;
          width: min-content;

          .slide {
            width: 573px;
            height: 357px;

            @include g.mq-down(lg) {
              width: g.spvw(344);
              height: g.spvw(210);
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit();
            }
          }
        }
      }

      .catch {
        font-size: 1.8rem;
        margin-bottom: 10px;

        @include g.mq-down(lg) {
          font-size: 1.6rem;
        }
      }

      .read {
        width: calc(100% - 30px);
        max-width: 613px;
        line-height: 2;
      }
    }
  }

  .staff-sec {
    padding: 0 0 100px;
    margin-top: -114px;
    overflow: hidden;

    @include g.mq-down(lg) {
      margin-top: 0;
    }

    .inner {

      .content-box {
        display: flex;
        margin-bottom: 116px;

        @include g.mq-down(lg) {
          display: block;
          margin-bottom: 60px;
        }

        .photo {
          width: g.pcminvw(462);
          height: 751px;

          @include g.mq-down(lg) {
            width: 100%;
            height: g.spvw(375);
            padding: 0;
            margin-bottom: 40px;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          width: calc(100% - g.pcminvw(462));
          padding-left: g.pcminvw(125);
          margin-top: 50px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
          }



          .midashi {
            color: g.$brown;
            font-size: 2rem;
            letter-spacing: 2px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 2rem;
              margin-bottom: 10px;
              padding: 0 30px;
              ;
            }
          }

          .catch {
            font-size: 2rem;
            letter-spacing: 2px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.6rem;
              padding: 0 30px;
            }
          }

          .read {
            width: calc(100% - 113px);
            max-width: 6430px;
            font-size: 1.4rem;
            line-height: 2;
            margin-bottom: 40px;

            @include g.mq-down(lg) {
              width: 100%;
              padding: 0 30px;
            }
          }

          .slides {
            @include g.mq-down(lg) {
              margin-left: 30px;
            }

            .slide {
              .wrap {
                width: 320px;
                margin-right: 45px;

                @include g.mq-down(lg) {
                  width: 64vw;
                  margin-right: 8vw;
                }

                figure {
                  width: 100%;
                  margin-bottom: 13px;
                  filter: saturate(0%);
                }

                .job {
                  margin-bottom: 10px;

                  .en {
                    display: block;
                    font-size: 1.2rem;
                    font-weight: 700;
                    color: g.$brown;
                    letter-spacing: 1px;
                    margin-bottom: 3px;
                  }

                  .ja {
                    display: block;
                    font-size: 1.6rem;
                    letter-spacing: 2px;
                  }
                }

                .read {
                  width: 100%;
                  text-align: justify;
                  margin-bottom: 0;

                  @include g.mq-down(lg) {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }


  }

  .contentsSec {
    padding: 110px 0 120px;
    position: relative;

    @include g.mq-down(lg) {
      padding: 60px 0 120px;
    }

    .midashi {
      padding-left: calc(50vw - 550px);
      font-size: 2.6rem;
      letter-spacing: 3px;
      color: g.$brown;
      margin-bottom: 40px;
      position: relative;

      @include g.mq-down(lg) {
        font-size: 2.2rem;
        padding-left: 0;
        margin-left: 20px;
        margin-bottom: 30px;
      }

      span {
        display: inline-block;
        background: #fff;
        padding-right: 20px;

      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: g.$grey;
        z-index: -1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50vw - 550px);

        @include g.mq-down(lg) {
          left: 0;
        }
      }
    }

    .slides {
      margin-left: calc(50vw - 550px);
      position: relative;
      z-index: 1;

      @include g.mq-down(lg) {
        margin-left: 20px;
      }

      .slide {
        .wrap {
          width: 240px;
          margin-right: 34px;

          @include g.mq-down(lg) {
            width: 64vw;
            margin-right: 8vw;
          }

          figure {
            width: 100%;
            margin-bottom: 13px;
          }

          .title {
            text-align: center;
            color: g.$brown;

            .en {
              display: block;
              font-size: 2rem;
              font-weight: 700;
              letter-spacing: 2px;
              margin-bottom: 3px;
            }
          }

          .read {
            text-align: justify;
          }
        }
      }
    }
  }
}
